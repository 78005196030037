<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes=" table-responsive">
            <file-explorer ref="fileExplorer" :indexFolder="documents" :root="endpoint" v-if="documents"/>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FileExplorer from "@/components/FileExplorer";
import { apiCallList } from '../../mixins'

export default {
    mixins: [apiCallList],
    watch: {
      $route() {
            this.endpoint = "documents?where-parent_id="+ (this.id ? this.id : 0),
            this.updateDocument = true;
            this.fetch();
          if(this.id){
            this.onReadDocuments();
          }      
        }
    },
    data(){
        return {
            endpoint : "documents?where-parent_id="+(this.id ? this.id : 0),
            itemSelected : {},
            options : true,
            documents : null,
            updateDocument : false
        }
    },
    components : {
        FileExplorer
    },
    props :{
      id : String
    },
    created(){
      if(this.id)
        this.onReadDocuments();
    },
    methods : {
        loadItems(){
            this.documents = this.data;
            if(this.updateDocument){
              this.$refs.fileExplorer.selectFolder = this.id;
              this.$refs.fileExplorer.updateDocuments(this.documents)
            }
        },
        onReadDocuments(){
          this.$axios("/read_notification/"+this.id)
            .then(() => {
                this.$store.dispatch('login/documentNotifications');
            })
              .catch (() => {
                //this.$store.dispatch ('isLoadingOff');
              });
        }
    }
}
</script>